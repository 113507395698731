/**
 * Refer:
 * UA to GA4 Migration: https://support.google.com/analytics/answer/10119380?hl=en
 */
import Auth from '@/modules/Auth';
import TagManager from 'react-gtm-module';

import {
  TRACK_AD_GROUP_ID_KEY,
  TRACK_AD_GROUP_NAME_KEY,
  TRACK_AD_ID_KEY,
  TRACK_AD_NAME_KEY,
  TRACK_CAMPAIGN_ID_KEY,
  TRACK_CAMPAIGN_KEY,
  TRACK_CLICK_ID_KEY,
  TRACK_CONTENT_KEY,
  TRACK_CREATIVE_ID_KEY,
  TRACK_MEDIUM_KEY,
  TRACK_SOURCE_KEY,
  TRACK_TWITTER_CLICK_ID_KEY
} from '../utility/constants';
import { TRACK_ROUTE_CHANGE } from './analyticsConsts';
import config from './config';
import localStorageService from './localStorageService';
import { isLocalHost } from './serverHelpers';
import sessionStorageService from './sessionStorageService';

export const initiateGTM = () => {
  const tagManagerArgs = {
    gtmId: config?.gtmId
  };

  TagManager.initialize(tagManagerArgs);
  //To track if tag manager is already loaded to prevent multiple loads
  window.gtmLoaded = true;
};

export const trackButtonClicks = (data = {}) => {
  window.dataLayer?.push({
    event: 'button_clicks',
    button: {
      text: data?.button?.text,
      location: data?.button?.location, //To identify where button is located
      description: data?.button?.description, //To identify which button specifically
      event: data?.button?.event, //To identify when button is triggered
      event_description: data?.button?.eventDescription //To identify what the button does
    },
    community: {
      ...data?.community
    }
  });
};

const trackNasioGeneralEventShared = ({
  eventId,
  payload,
  isExtra = false
}) => {
  const EVENT_NAME = isExtra
    ? 'nasio_general_events_extra'
    : 'nasio_general_events';

  if (typeof window === 'undefined') {
    return;
  }

  let data = {
    action: eventId,
    timestamp: new Date().getTime(),

    ...payload
  };

  //this change is only applicable to client-side
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const user = Auth.getUserDataSession();

  // Add email and userId by default if user is logged in
  if (user) {
    data = {
      ...data,
      email: user?.email,
      userId: user?._id,
      languagePreference: user?.learner?.languagePreference
    };
  }

  // Log data tracked to console in local environment
  if (isLocalHost()) {
    console.info(
      `## Tracking ${EVENT_NAME}.
      Event action: ${eventId},
      Payload: `,
      data
    );
  }

  window.dataLayer.push({
    event: EVENT_NAME,
    data
  });
};

export const trackGAEvent = async (eventId, payload) => {
  trackNasioGeneralEventShared({ eventId, payload, isExtra: false });
};

export const trackExtraGAEvent = async (eventId, payload) => {
  trackNasioGeneralEventShared({ eventId, payload, isExtra: true });
};

export const trackGAEventWithCommunityAndUser = (
  eventId,
  payload,
  isExtra = false // If true, it will track in "nasio_general_events_extra". if false, it will track in "nasio_general_events"
) => {
  try {
    // Get User Data from Local Storage
    const user = Auth.getUserDataSession();
    // Get Community Data from Session Storage
    const community = JSON.parse(
      sessionStorageService.getItem('activeCommunity') ?? '{}'
    );
    const fullPayload = {
      ...(payload || {}),
      // This is override to the correct storage based answer
      communityCode: community?.code,
      communityId: community?._id,
      email: user?.email,
      userId: user?._id,
      languagePreference: user?.learner?.languagePreference
    };

    if (isExtra) {
      trackExtraGAEvent(eventId, fullPayload);
    } else {
      trackGAEvent(eventId, fullPayload);
    }
  } catch (error) {
    console.warn(error);
  }
};

export const trackRouteChange = (route) => {
  const payload = {
    link: route
  };

  const isExtra = true;
  trackGAEventWithCommunityAndUser(TRACK_ROUTE_CHANGE, payload, isExtra);
};

export const trackFormSubmission = (data = {}) => {
  window.dataLayer?.push({
    event: 'form_submission',
    form: {
      type: data?.form?.type, // which form it is
      event: data?.form?.event // To idenitfy where button is located
    },
    community: {
      ...data?.community
    }
  });
};

// when cta is clicked
export const trackInitiateCheckout = (data = {}) => {
  trackGAEvent('initiate_checkout', data);
};

// when email is filled
export const trackBeginCheckout = (data = {}) => {
  trackGAEvent('checkout_fill_email', data);
};

// Tracking homepage clicks
// Track button click

/**
 *
 * {
 *  page: 'HomePage',
 *  section: 'Banner',
 *  time: <time>,
 *  time: <timezone>
 * }
 */
export const startCommunityForFreeButtonClick = (data = {}) => {
  window.dataLayer?.push({
    event: 'startCommunityForFreeButtonClick',
    data
  });
};

export const trackSelectStripeClick = () => {
  window.dataLayer?.push({
    event: 'SelectPaymentOptionStripe',
    data: {
      category: 'stripe',
      action: 'click',
      label: 'stripe'
    }
  });
};

export const trackSelectRapydOptionClick = (option) => {
  window.dataLayer?.push({
    event: `SelectPaymentOptionRapyd-${option}`,
    data: {
      category: 'rapyd',
      action: 'click',
      label: option
    }
  });
};

// Uses ecommerce from prev ecommerce push aka CoursePageView
// data should have email, and lead_type
export const trackLead = (data = {}) => {
  window.dataLayer?.push({
    event: 'Lead',
    data: {
      email: data.email,
      phone: data.phoneNumber,
      lead_type: data.leadType
    }
  });
};

export const getTrackingInformationForBE = (params = {}) => {
  let trackingData = {
    source: 'organic',
    ...params
  };

  if (typeof window !== 'undefined') {
    if (localStorageService.getItem(TRACK_CLICK_ID_KEY)) {
      trackingData[TRACK_CLICK_ID_KEY] =
        localStorageService.getItem(TRACK_CLICK_ID_KEY);
    }
    if (localStorageService.getItem(TRACK_SOURCE_KEY)) {
      trackingData[TRACK_SOURCE_KEY] =
        localStorageService.getItem(TRACK_SOURCE_KEY);
    }
    if (localStorageService.getItem(TRACK_MEDIUM_KEY)) {
      trackingData[TRACK_MEDIUM_KEY] =
        localStorageService.getItem(TRACK_MEDIUM_KEY);
    }
    if (localStorageService.getItem(TRACK_AD_GROUP_ID_KEY)) {
      trackingData[TRACK_AD_GROUP_ID_KEY] = localStorageService.getItem(
        TRACK_AD_GROUP_ID_KEY
      );
    }
    if (localStorageService.getItem(TRACK_AD_GROUP_NAME_KEY)) {
      trackingData[TRACK_AD_GROUP_NAME_KEY] = localStorageService.getItem(
        TRACK_AD_GROUP_NAME_KEY
      );
    }
    if (localStorageService.getItem(TRACK_AD_ID_KEY)) {
      trackingData[TRACK_AD_ID_KEY] =
        localStorageService.getItem(TRACK_AD_ID_KEY);
    }
    if (localStorageService.getItem(TRACK_AD_NAME_KEY)) {
      trackingData[TRACK_AD_NAME_KEY] =
        localStorageService.getItem(TRACK_AD_NAME_KEY);
    }
    if (localStorageService.getItem(TRACK_CAMPAIGN_ID_KEY)) {
      trackingData[TRACK_CAMPAIGN_ID_KEY] = localStorageService.getItem(
        TRACK_CAMPAIGN_ID_KEY
      );
    }
    if (localStorageService.getItem(TRACK_CAMPAIGN_KEY)) {
      trackingData[TRACK_CAMPAIGN_KEY] =
        localStorageService.getItem(TRACK_CAMPAIGN_KEY);
    }
    if (localStorageService.getItem(TRACK_CONTENT_KEY)) {
      trackingData[TRACK_CONTENT_KEY] =
        localStorageService.getItem(TRACK_CONTENT_KEY);
    }
    if (localStorageService.getItem(TRACK_CREATIVE_ID_KEY)) {
      trackingData[TRACK_CREATIVE_ID_KEY] = localStorageService.getItem(
        TRACK_CREATIVE_ID_KEY
      );
    }
    if (localStorageService.getItem(TRACK_TWITTER_CLICK_ID_KEY)) {
      trackingData[TRACK_TWITTER_CLICK_ID_KEY] =
        localStorageService.getItem(TRACK_TWITTER_CLICK_ID_KEY);
    }
  }
  return trackingData;
};

export const storeTrackingInformation = (router_query) => {
  if (
    router_query?.irclickid ||
    router_query?.fbclid ||
    router_query?.gclid ||
    router_query?.ttclid ||
    router_query?.twclid ||
    router_query?._kx
  ) {
    localStorageService.removeItem(TRACK_AD_GROUP_ID_KEY);
    localStorageService.removeItem(TRACK_AD_GROUP_NAME_KEY);
    localStorageService.removeItem(TRACK_AD_ID_KEY);
    localStorageService.removeItem(TRACK_AD_NAME_KEY);
    localStorageService.removeItem(TRACK_CAMPAIGN_ID_KEY);
    localStorageService.removeItem(TRACK_CAMPAIGN_KEY);
    localStorageService.removeItem(TRACK_CREATIVE_ID_KEY);
  }
  if (router_query?.irclickid) {
    localStorageService.setItem('irclickid', router_query?.irclickid);
    localStorageService.setItem(
      TRACK_CLICK_ID_KEY,
      router_query?.irclickid
    );
    localStorageService.setItem(TRACK_SOURCE_KEY, 'impact');
    localStorageService.removeItem(TRACK_MEDIUM_KEY);
  } else if (router_query?.fbclid) {
    localStorageService.setItem(TRACK_CLICK_ID_KEY, router_query?.fbclid);
    localStorageService.setItem(TRACK_SOURCE_KEY, 'fb');
    localStorageService.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?.gclid) {
    localStorageService.setItem(TRACK_CLICK_ID_KEY, router_query?.gclid);
    localStorageService.setItem(TRACK_SOURCE_KEY, 'google');
    localStorageService.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?.ttclid) {
    localStorageService.setItem(TRACK_CLICK_ID_KEY, router_query?.ttclid);
    localStorageService.setItem(TRACK_SOURCE_KEY, 'tiktok');
    localStorageService.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?.twclid) {
    sessionStorageService.setItem(
      TRACK_TWITTER_CLICK_ID_KEY,
      router_query?.twclid
    );
    localStorageService.setItem(TRACK_CLICK_ID_KEY, router_query?.twclid);
    localStorageService.setItem(TRACK_SOURCE_KEY, 'twitter');
    localStorageService.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?._kx) {
    localStorageService.setItem(TRACK_CLICK_ID_KEY, router_query?._kx);
    localStorageService.setItem(TRACK_SOURCE_KEY, 'klaviyo');
    localStorageService.setItem(TRACK_MEDIUM_KEY, 'email');
  }
  // utm parameters take precedence for source, medium and campaign
  if (router_query?.utm_source) {
    localStorageService.setItem(
      TRACK_SOURCE_KEY,
      router_query?.utm_source
    );
  }
  if (router_query?.utm_medium) {
    localStorageService.setItem(
      TRACK_MEDIUM_KEY,
      router_query?.utm_medium
    );
  }
  if (router_query?.adgroupid) {
    localStorageService.setItem(
      TRACK_AD_GROUP_ID_KEY,
      router_query?.adgroupid
    );
  }
  if (router_query?.adgroupname) {
    localStorageService.setItem(
      TRACK_AD_GROUP_NAME_KEY,
      router_query?.adgroupname
    );
  }
  if (router_query?.adid) {
    localStorageService.setItem(TRACK_AD_ID_KEY, router_query?.adid);
  }
  if (router_query?.adname) {
    localStorageService.setItem(TRACK_AD_NAME_KEY, router_query?.adname);
  }
  if (router_query?.campaignid) {
    localStorageService.setItem(
      TRACK_CAMPAIGN_ID_KEY,
      router_query?.campaignid
    );
  }
  if (router_query?.utm_campaign) {
    localStorageService.setItem(
      TRACK_CAMPAIGN_KEY,
      router_query?.utm_campaign
    );
  }
  if (router_query?.utm_content) {
    localStorageService.setItem(
      TRACK_CONTENT_KEY,
      router_query?.utm_content
    );
  }
  if (router_query?.creativeid) {
    localStorageService.setItem(
      TRACK_CREATIVE_ID_KEY,
      router_query?.creativeid
    );
  }
};

export const initializeTrackUserId = (id) => {
  window.dataLayer?.push({ userId: id });
};

export const trackTikTokIdentity = (data = {}) => {
  window.dataLayer?.push({ event: 'TikTokIdentity', data });
};

export const getCommunityDataForAnalytics = (community) => {
  return {
    communityId: community?._id
  };
};
